/*============================================================================
  TYPOGRAPHY
==============================================================================*/
//icons
@font-face {
  font-family: 'icons';
  src:  url('../fonts/icons.eot?wctro7');
  src:  url('../fonts/icons.eot?wctro7#iefix') format('embedded-opentype'),
  url('../fonts/icons.ttf?wctro7') format('truetype'),
  url('../fonts/icons.woff?wctro7') format('woff'),
  url('../fonts/icons.svg?wctro7#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-kruisje:before {
  content: "\e907";
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-calendar:before {
  content: "\e904";
}
.icon-facebook:before {
  content: "\e905";
}
.icon-instagram:before {
  content: "\e906";
}
.icon-twitter:before {
  content: "\e908";
}
.icon-checkmark:before {
  content: "\e909";
}
.icon-linkedin:before {
  content: "\e916";
}
.icon-chevron-thin-down:before {
  content: "\e901";
}


// Font Stacks

@font-face {
  font-family: 'brownbold';
  src: url('../fonts/brown-bold-webfont.woff2') format('woff2'),
  url('../fonts/brown-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@mixin default-font($size: 18, $weight: 300, $lineHeight: 1.5em) {
  font-family: 'basic-sans', Arial, Verdana, sans-serif;
  @include fs($size);
  line-height: $lineHeight;
  font-weight: $weight;
  @include optimize-font();
}

@mixin display-font() {
  font-family: 'brownbold', Arial, Verdana, sans-serif;
  font-weight: 700;
}

// Body

p {
  line-height: 1.5em;
  margin-bottom: 0.5em;
}

p.large {
  @include fluid-type(font-size, $mediumSize, $largerSize, 25px, 30px);
}

strong {
  font-weight: 600;
  font-size: inherit;
}

em {
  font-style: italic;
  font-weight: inherit;
}

a {
  font-weight: inherit;
  font-style: inherit;
  color: $linkColor;
  transition: $transitionDefault;
  text-decoration: none;
  text-decoration-skip: ink;
  // @include smart-underline; cross browser text-decoration skip

  &:hover, &:focus {
    color: $linkColorHover;
    text-decoration: none;
  }

  &:focus, &:active {
    outline: 0;
  }

  &.tdn {
    text-decoration: none;
    &:hover, &:focus {
      text-decoration: underline;
    }
  }
}

a.accent-link {
  color: $accent;
  text-decoration: underline;
  font-weight: 600;
}

a[href^=tel] {
  text-decoration: none;

  &:hover, &:focus {
    text-decoration: none;
  }
}

a:not([href]) {
  color: $black;
  text-decoration: none;
  &:hover, &:focus {
  }
}

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
}

// Headings

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  @include display-font;
  @include optimize-font;
  color: $accent;
  line-height: 1.2em;
  margin: 0 0 0.8em;

  a {
    text-decoration: none;
    outline: 0;
    font-weight: inherit;
    color: $accent;

    &:hover, &:focus {
      color: $accentHover;
    }
  }
}

h1, .h1 {
  @include fluid-type(font-size, $mediumSize, $largerSize, 30px, 40px);
}

h2, .h2 {
  @include fluid-type(font-size, $mediumSize, $largerSize, 25px, 35px);
  color: $yellow;
  /* text-transform: uppercase;
   letter-spacing: 4px;
   margin-bottom: 70px;
   line-height: 1.6em;*/
}

h3, .h3 {
  @include fluid-type(font-size, $mediumSize, $largeSize, 16px, 22px);
}

h4, .h4 {
  //@include fs(16);
  //spelletjes knutselen etc

  @include fluid-type(font-size, $mediumSize, $largerSize, 18px, 22px);
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-bottom: 70px;
  line-height: 1.6em;

}

h5, .h5 {
}

h6, .h6 {
}

.subsection-title__title {
  @include display-font;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  @include fs(22);
  @include lh(29);
  font-weight: 600;
  display: block;

}

.subsection-title__tagline {
  @include default-font;
  display: block;
  @include fs(18);
  @include lh(29);
  font-style: italic;
  font-weight: 400;

}

// Lists

ul, ol {
  margin: 0 0 1.5em 16px;

  li {
    line-height: 1.5em;
    padding: 0;
  }
}

ul ul, ul ol, ol ol, ol ul {
  margin-bottom: 0;
}

ul {
  list-style: disc;

  ul {
    list-style: circle;
  }

  &.lined {
    @extend .unstyled;
    li {
      border-bottom: 1px solid #E3E3E3;
      padding: 5px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
}

ol {
  list-style: decimal;
}

.unstyled, nav ul {
  margin: 0 0 1.5em;
  padding: 0;
  list-style: none;

  ul {
    list-style: none;
  }

  li {
    list-style: none;
    list-style-image: none;
    padding: 0;
    background: none;
  }
}

nav ul {
  @include clearfix;
}

dl {
  margin: 0 0 1.5em;
}

dt, dd {
  line-height: 1.5em;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5em;
}

// Misc

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $gray;
  margin: 50px 0;
}

blockquote {
  line-height: inherit;
  padding-left: 20px;
  border-left: 1px solid $gray;
  margin-bottom: 1.5em;
  @include fs(20);
  font-style: italic;

  p {
    margin-bottom: 0.5em;
  }

  cite {
    @include fs(14);
  }

  @media #{$medium} {
    padding-left: 40px;
  }
}
