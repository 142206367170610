
/*============================================================================
  FORMS
==============================================================================*/

form {
  margin: 0;
}

legend {
  border: 0; padding: 0; white-space: normal; *margin-left: -7px;
  font-size: 18px;
}

button, input, select, textarea, .button {
  margin: 0; vertical-align: baseline; *vertical-align: middle;
  transition: $transitionDefault;
}

button, input {
  line-height: normal;
}

button, select {
  text-transform: none;
}

// When having a body font-size < 16px
// Prevent zoom on touch devices in active inputs
// @media #{$maxMedium} {
//   input, textarea {
//     font-size: 16px;
//   }
// }

label, .label, input, select, textarea {
  @include default-font();
  vertical-align: middle; color: $black;
}


label, .label {
  display: block; margin-bottom: 8px;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;  cursor: pointer; *overflow: visible; border: none;
}

input[type="checkbox"], input[type="radio"] {
  cursor: pointer;
}

input[type="text"], input[type="search"], input[type="password"],
input[type="email"], input[type="tel"], input[type="date"], textarea, select {
  padding: 6px; margin-bottom: 2px; display: block;
  background: #fff; height: 50px; line-height: inherit; width: 100%; border: 1px solid $gray;
  &:focus { outline: 0; border-color: $grayDark; }
}

input[type="email"] {
  display: block;
}

textarea {
  overflow: auto; vertical-align: top; resize: vertical; width: 100%; height: 185px;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0; padding: 0;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box; width: auto; height: auto; padding: 0;
  margin: 3px 0; *margin-top: 0; *height: 13px; *width: 13px; line-height: normal; border: none;
}

input[type="file"] {
  background-color: #fff; padding: initial; border: initial; line-height: initial;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

// Buttons
input[type="reset"], input[type="submit"], .button {
  @include display-font;
  float: left; display: block;
  //clear: both;
  transition: $transitionDefault;
  outline: 0;
  line-height: 1.2em;
  padding: 15px 40px;
  margin: 10px 20px 0 0;
  text-decoration: none;
  color: $accent;
  background-color: transparent;
  border-radius: 30px;
  @include fs(16);
 // height: 50px;
  text-align: center;
  border: 1px solid $accent;
  text-transform: uppercase;
  letter-spacing: 0.1em;

  @media #{$large} {
    padding: 20px 85px;
    //@include lh(25);
  }

  i { margin: -5px 6px 0 5px; }
  span {
    position: relative;
    top: -1px;
  }

  &:hover, &:focus, &:active {
   background-color: $yellow;
    color: $accent;

    border: 1px solid transparent;
  }

  &:active {
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.2);
    transform: translateY(1px);
  }

  &::-moz-focus-inner {
    border: 0;
  }
}

.button--less-padding.button--less-padding {
  @media #{$large} {
    padding: 20px 50px;
    //@include lh(25);
  }
}
.button--yellow.button--yellow {
  color: $accent;

  border: 1px solid transparent;
  background-color: $yellow;
  &:hover, &:focus, &:active {
    //opacity: 0.8;
    color: $white;
    background-color: $accent;

    span {
      color: $white;
    }
  }
}

.padding-block--accent {
  .button--yellow.button--yellow {
    &:hover, &:focus, &:active {

      border-color: $white;

    }
  }
}

.button--contactinfo.button--contactinfo {
  text-transform: none;
  @include default-font();
  @include fs(18);
  float: none;
  padding: 15px 40px,;
  &:hover, &:focus, &:active {

  }
}

.button--wide {
  width: 100%;
  max-width: 465px;

}
.button--full {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.button--small {
  @include fs(16);
  padding: 10px 18px;
}

button, input[type="text"], input[type="submit"], input[type="button"], input[type="reset"],
input[type="email"], input[type="tel"], textarea {
  -webkit-appearance: none;
}

button, input[type="reset"], input[type="submit"], .button, a.button, select {
  &:focus {
    outline: 0; *outline: inherit;
    box-shadow: 0 0 1px 1px rgba(200,200,200,0.1);
  }
}

::placeholder { color: #999; font-style: italic; }

// Hide clear text field ie 10+
::-ms-clear { display: none; }

// Remove the rounded corners
input[type="search"] { -webkit-appearance: none; }

// Hide the cancel button
::-webkit-search-cancel-button { -webkit-appearance: none; }

// Hide the magnifying glass
::-webkit-search-results-button { -webkit-appearance: none; }

::-webkit-textfield-decoration-container { }

// Hide number input spinner
::-webkit-inner-spin-button { -webkit-appearance: none; }
::-webkit-outer-spin-button { -webkit-appearance: none; }

// Style file upload button
::-ms-browse, ::-webkit-file-upload-button { }

.checkbox, .radio {
  position: relative;


  label {
    padding-left: 35px;
    cursor: pointer;
    @include fs(16);


    &:before, &:after {
      content: "";
      display: block;
      position: absolute;
      transform: $transitionDefault;
    }

    &:before {
      left: 0; top: 0;
      margin-top: 5px;
      width: 20px; height: 20px;
      border: 1px solid $gray;
    }

    &:after {
      top: 7px; left: 6px;
      border: solid $black;
      border-width: 0 3px 3px 0;
      width: 8px; height: 13px;
      transform: rotate(45deg);
      opacity: 0;
    }
  }

  input[type="checkbox"], input[type="radio"] {
    position: absolute; top: 0; left: 0;
    z-index: -1;
    visibility: hidden;

    &:checked + label {
      &:before {
        border-color: $grayDark;
      }

      &:after {
        opacity: 1;
      }
    }
  }
}

.radio {
  label {
    &:before, &:after {
      border-radius: 100%;
    }

    &:after {
      border: 0;
      transform: none;
      top: 4px; left: 4px;
      width: 12px; height: 12px;
      background-color: $black;
    }
  }
}

// Forms
form {
  .row {
    margin-bottom: 0;
  }
}

fieldset {
  width: auto;

  p, ul, li {
    @include clearfix;
  }

  ul {
    margin-left: -20px;

    li {
      list-style: none; background: none; list-style-image: none;
      margin: 0 0 10px; float: left; width: 100%; padding: 0 0 0 20px;

      &.error {
        input {
          border-color: $error;
        }
      }

      &.space {
        margin-top: 25px;
      }

      @media #{$medium} {
        &.one-half { width: 50%; }
        &.one-fourth { width: 25%; }
      }
    }
  }
}

.buttons {
  @include clearfix;
  button, input[type="reset"], input[type="submit"], .button, a.button {
    float: left; clear: none; margin-right: 15px;

    &.right {
      float: right;
      margin-right: 0;
      margin-left: 15px;
    }
  }
}

.a-c {
  .button, a.button, button {
    float: none; clear: none; display: inline-block;
  }
}

// Recaptcha
.grecaptcha-badge { display: none; }
