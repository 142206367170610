/**
 * OVERVIEW
 *
 * IMPORTS
 * reset, vars, mixins, core, plugins
 *
 * LAYOUT
 * Helpers
 * Header / Main / Footer
 * Components
 * Pages (page overrides)
 *
 * Hooks (IE, ...)
 * Print
 */
/**
 * GUIDELINES
 * CLASSES
 * Naming: BEM - http://getbem.com/
 * Abbreviations: Emmet - http://docs.emmet.io/cheat-sheet/ | example: .va-m
 *
 * MEDIAQUERIES
 * @media $small, $medium, $maxMedium, $large, $larger, $huge, $massive
 */
/* =============================================================================
   IMPORTS
   ========================================================================== */
// CSS Reset
@import "inc/reset";
// Core
@import "inc/vars";
@import "inc/mixins";
//@import "inc/animations";
//@import "inc/custom-animations";
// Styled patterns and elements
@import "inc/grid";
@import "inc/type";
@import "inc/tables";
@import "inc/forms";
// Plugins
@import "plugins/validation-engine";
@import "plugins/fancybox";

/* =============================================================================
   LAYOUT
   ========================================================================== */

html {
  font-size: 62.5%;
}

body {
  @include default-font;
  color: $black;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

.page-wrap {
  position: relative;

  @media #{$medium} {
    min-height: 100vh;
    z-index: 2;
  }
}

.container {
  position: relative;
  max-width: 1430px;
  margin: 0 auto;
  padding: 0 20px;

  @media #{$large} {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.container--medium {
  max-width: 1180px;
}

.container--small {
  max-width: 830px;
}

// Typekit async loading (all elements using $ typekit font)
.wf-loading {
  overflow: hidden;
  body {
    visibility: hidden;
  }
}

::selection {
  background-color: $selection;
  color: inherit;
}

[contenteditable='true'] {
  background-color: rgba($white,0.5);
}



/* =============================================================================
   HELPERS
   ========================================================================== */

// Icons
.icon {
  display: inline-block;
  vertical-align: middle;
}

.icon-hamburger, .icon-hamburger:before, .icon-hamburger:after {
  width: 24px;
  position: absolute;
  left: 0;
  height: 1px;
  border-top: 3px solid $accent;
  transition: $transitionDefault;
}

.icon-hamburger {
  position: relative;
  &:before, &:after {
    content: "";
    margin-left: 0;
  }
  &:before {
    margin-top: 3px;
  }
  &:after {
    margin-top: 9px;
  }
}

.pos-r {
  position: relative;
}

// Exceptions: only add what you need!
.mb0.mb0 {
  margin-bottom: 0;
}

// .mb20.mb20 { margin-bottom: 20px; }
.mb100.mb100 {
  margin-bottom: 50px;

  @media #{$medium} {
    margin-bottom: 100px;
  }
}

.mb200.mb200 {
  margin-bottom: 50px;

  @media #{$medium} {
    margin-bottom: 100px;
  }
  @media #{$larger} {
    margin-bottom: 200px;
  }
}

.mb-gutter {
  margin-bottom: $gutter;
}

.mb-gutter-x2 {
  margin-bottom: $gutter *2;
}

.mb-gutter-x3 {
  margin-bottom: $gutter *3;
}

.mb-gutter-x4 {
  margin-bottom: $gutter *2;
  @media #{$medium} {
    margin-bottom: $gutter *4;
  }
}

.a-c.a-c {
  text-align: center;
}

.a-l.a-l {
  text-align: left;
}

.a-r.a-r {
  text-align: right;
}

@media #{$medium} {
  .medium--a-c.medium--a-c {
    text-align: center;
  }

  .medium--a-l.medium--a-l {
    text-align: left;
  }

  .medium--a-r.medium--a-r {
    text-align: right;
  }
}

// .mt0.mt0 { margin-top: 0; }
// .mt20.mt20 { margin-top: 20px; }
// .mt40.mt40 { margin-top: 40px; }

// .ttu { text-transform: uppercase; }
// .ttl { text-transform: lowercase; }

// Google maps mw
.gm-style img {
  max-width: inherit;
}

// Responsive video
.video-container {
  position: relative;
  padding: 0 0 56.25%;
  height: 0;
  overflow: hidden;
  margin: 0 0 15px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// Equal height
.equal-h {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;

  > * {
    display: flex;
  }

  &:before, &:after {
    display: none;
  }
}

html.no-flexbox {
  .equal-h {
    height: 100%;
    > div {
      float: left;
    }
  }
}

// Vertical align (not recommended)
// .va-wrap, .va-m, .va-b { display: table; width: 100%; height: 100%; }
// .va-wrap { table-layout: fixed; }
// .va-m, .va-b { display: table-cell; }
// .va-m { vertical-align: middle; }
// .va-b { vertical-align: bottom; }

// Block link
.block-link {
  position: relative;
  cursor: pointer;
}

.block-link__target {
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

/* =============================================================================
   HEADER
   ========================================================================== */

.page-header {
  @include clearfix;

  overflow: visible;
  padding: 5px 0;
  /* height: 70px;*/
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 11;
  background-color: $white;
  @media #{$medium} {
    padding: 20px 0;
    height: auto;
    position: relative;
  }
  @media #{$larger} {
    //  margin-bottom: -150px;
  }
}

.page-header__container {
  z-index: 99;
  /* @media #{$medium} {
     display: flex;
     align-items: center;
     justify-content: center;
   }

   @media #{$larger} {
     display: block;
   }*/
}

.page-header__logo {
  text-decoration: none;
  @include opacity(100);
  float: left;
  //position: absolute;
  overflow: visible;
  //  margin-top: 10px;

  @media #{$medium} {
    position: absolute;
  }

  img {
    width: 85px;
    @media #{$medium} {

      width: 150px;
    }
    @media #{$larger} {
      width: 175px;
    }
    @media #{$huge} {
      width: 225px;
    }
  }

  @media #{$larger} {
    //  margin-top: 50px;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    width: 1070px;
    height: 830px;
    background-size: cover;
    background-position: center;
    background-image: url("../images/header-accent-blob.png");
    pointer-events: none;
    transform: translate(-50%, -50%);
    display: none;
    max-width: 1500px;

    @media #{$medium} {

      // margin-left: -150px;
    }

    @media #{$large} {
      display: block;
      width: 802.5px;
      height: 622.5px;
      margin-left: -100px;
      margin-top: -2vw;

      width: 53vw;

      //height: 830px;
      height: 42.5vw;
      margin-left: -5vw;
    }

    @media #{$larger} {
      //width: 1070px;
      width: 66.25vw;
      margin-top: -5vw;

      //height: 830px;
      height: 52.5vw;
      //margin-top: -50px;
      margin-left: -7vw;
    }

    @media #{$massive} {

    }

  }

  img {
    display: block;
    position: relative;
    z-index: 11;
    transition: $transitionDefault;
  }

  &:hover, &:focus {
    img {
      @include opacity(80);
    }

  }
}

.main-nav {
  text-transform: uppercase;
  @include optimize-font;
  color: $black;
  //margin-top: 20px;

  a {

  }

  @media #{$medium} {
    margin: 35px 0 0 40px;
    float: right;

  }

}

.main-nav__trigger {
  display: block;
  position: relative;
  padding: 12px 20px 12px 55px;
  background-color: transparent;
  color: $black;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 5px;
  top: 15px;
  right: 20px;
  border: 1px solid #efefef;
  z-index: 99;
  height: 50px;

  .icon-hamburger {
    position: absolute;
    left: 10px;
    top: 18px;
  }

  &:hover, &:focus {
    background-color: transparent;
    box-shadow: none;
  }

  &:active {
    transform: none;
  }

  @media #{$large} {
    display: none;
  }
}

.main-nav__wrap {
  display: none;
  margin: 0 0 15px;

  @media #{$maxLarge} {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
    padding: 20px;
    padding-top: 200px;
    margin-bottom: 0;

    text-align: center;

  }

  @media #{$large} {
    margin: 0 0 0 -20px;
    display: block;
    overflow: visible;
  }
}

.main-nav__item {

  @media #{$large} {
    float: left;
    margin: 0 0 0 20px;
  }

  @media #{$larger} {
    margin-left: 45px;
  }

  a {

    text-decoration: none;
    padding: 12px 15px;
    display: block;
    border-top: 1px solid $grayLight;
    letter-spacing: 0.2em;

    @include display-font;
    font-weight: 700;

    text-transform: uppercase;
    color: $accent;
    @include fs(14);

    border-bottom: 3px solid transparent;

    @media #{$large} {
      border-top: 0;
      padding: 0;
      padding-bottom: 10px;
    }

  }

  a:hover, a:focus, &.active a {
    color: $accent;
    @media #{$large} {
      border-bottom: 3px solid $yellow;
    }
  }

  @media #{$maxLarge} {
    &.active {
      background-color: lighten($yellow, 20%);
      a {
        border: none;
      }
    }
  }
}

.skip-link {
  left: -999px;
  position: absolute;
  top: auto;
  overflow: hidden;
  z-index: -999;
  white-space: nowrap;

  &:focus {
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #fff;
    padding: 0.5em 1em;
    @include fs(20);
  }
}

/* =============================================================================
   MAIN
   ========================================================================== */

.main {
  padding: 0;
  overflow: hidden;
  padding-top: 90px;

  @media #{$medium} {
    padding: 40px 0 80px;
  }
  @media #{$larger} {
    padding: 20px 0 80px;
  }
}

/* =============================================================================
   FOOTER
   ========================================================================== */

.page-footer {
  position: relative;
  text-align: center;

  .grid {
    margin-bottom: 0;
  }

  p {
    @include fs(18);

    &.large {
      @include fluid-type(font-size, $mediumSize, $largerSize, 20px, 30px);
    }
  }

  @media #{$medium} {
    height: 80px;
    margin: -80px 0 0;
    z-index: 5;
  }
}


.page-footer__top {
  background-color: $accent;
  color: $white;
  padding: 50px 0;
  display: flex;
  align-items: center;

  .button {
    float: none;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  @media #{$medium} {
    height: 370px;

    .button {
      display: inline-block;
      margin-left: 7px;
      margin-right: 7px;
    }
  }
}

.accent-list {
  li {
    color: $accent;

    span {
      color: $black;
    }
  }
}

.page-footer__bottom {
  background-color: $pink;
  color: $accent;
  @include fs(18);
  position: relative;
  padding: 20px 0;

  ul {
    margin-bottom: 20px;
  }

  .footer-social-item {

    i {
      position: relative;
      top: 2px;
    }

    @media #{$maxMedium} {
      display: inline-block;
      margin-right: 10px;
      margin-top: 10px;
    }
  }

  p {
    margin-bottom: 0;
  }

  a {
    color: $accent;

    &:hover, &:focus {
      opacity: 0.8;
    }
  }

  @media #{$medium} {
    height: 80px;
    display: flex;
    align-items: center;

    ul {
      margin-bottom: 0;

      li {
        display: inline-block;
        margin: 0 10px;
        position: relative;
        line-height: 22px;

        &:after {
          content: '';
          position: absolute;
          height: 22px;
          width: 1px;
          background-color: $accent;
          right: -11px;
          top: 0;
        }

        &:last-child:after {
          display: none;
        }
      }
    }
  }
}

a.esign {
  width: 80px;
  height: 20px;
  background-image: url("../images/logo-esign.svg");
  background-size: 77px 20px;
  background-repeat: no-repeat;
  background-position: top left;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  text-indent: -9999px;
  opacity: 0.3;
  transition-duration: 0.5s;
  display: block;
  outline: 0;
  z-index: 9999;
  margin: 0 auto;

  &:hover, &:focus {
    opacity: 1;
  }

  @media #{$medium} {
    width: 16px;
    position: absolute;
    right: 30px;
    bottom: 50%;
    transform: translateY(50%);

    &:hover, &:focus {
      opacity: 1;
      width: 80px;
    }
  }
}

/* =============================================================================
   COMPONENTS
   ========================================================================== */

// Rte
.large {
  @include fs(20);
}

.rte--list {
  ul {
    list-style: none;
    margin-left: 0;
    li {
      position: relative;
      padding-left: 20px;
      vertical-align: top;
      &:before {
        content: '';
        width: 6px;
        height: 6px;
        background-color: $accent;
        position: absolute;
        left: 0;
        top: 0;
        margin-top: 10px;
        border-radius: 50%;
        //margin-left: -10px;

      }
    }
  }
}


.center-block {
  @include clearfix;
  text-align: center;
  margin: 20px 0;

  .button {
    float: none;
    display: inline-block;
  }
}

// Article
section {
  position: relative;
}

article {
  @include clearfix;
  margin: 0 0 20px;

  a {
    img {
      display: block;
      @include opacity(100);
    }

    &:hover, &:focus {
      img {
        @include opacity(80);
      }
    }
  }
}

.article-detail {

}

.article-detail__image {
  margin-bottom: 1.5em;
}

// Note
.note {
  @include clearfix;
  margin: 0 0 20px;
  padding: 15px 20px 0;
  background: #efefef;

  ul, p {
    margin-bottom: 1em;
  }
}

.note--success {
  color: $success;
  background-color: $successBg;
}

.note--error {
  color: $error;
  background-color: $errorBg;
}

// Slideshow
.cycle-slideshow {
  width: 100%;
  position: relative;
}

.cycle-pager {
  width: 100%;
  text-align: center;

  span {
    display: inline-block;
    margin: 0 3px;
    font-size: 36px;
    height: 20px;
    width: 20px;
    line-height: 14px;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 20px;
    color: #fff;
    overflow: hidden;
    text-indent: -500px;
    transition: $transitionDefault;

    &:hover, &:focus {
      transition: none;
    }
  }

  span:hover, span:focus, .cycle-pager-active {
    text-indent: 0;
  }
}

// Map
.map {
  width: 100%;
  height: 350px;
  background: #efefef;
  margin: 0 0;

  @media #{$medium} {
    height: 550px;
  }
  @media #{$larger} {
    height: 750px;
  }
}

.map-wrapper {
  position: relative;
  overflow: hidden;
}

.map-wrapper__info {
  color: $accent;
  background-color: $yellow;
  padding: 20px;

  @media #{$medium} {

    background-color: transparent;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 11;
    padding: 40px;
    // margin-bottom: -100px;

    &:before {
      content: '';
      bottom: 0;
      right: 0;
      /*    left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);*/
      margin-bottom: -50px;
      margin-right: -100px;
      z-index: -1;
      background-image: url("../images/map-blob-yellow.png");
      width: 564.02px;
      height: 387.75px;
      position: absolute;
    }
  }

  @media #{$larger} {
    margin-right: 600px;

  }
}

.map-wrapper__visual {
  display: none;
  @media #{$larger} {
    display: block;
    bottom: 0;
    right: 0;

    margin-bottom: -150px;
    margin-right: -400px;
    z-index: 11;
    width: 994.11px;
    height: 683.43px;
    position: absolute;
  }
}

// Social
.social {
  @include clearfix;
  list-style: none;
  margin: 0 0 2em -5px;
}

.social__item {
  padding: 0 0 5px 5px;
  float: left;
}

.social__link {
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: block;
  border-radius: 40px;
  background-color: $accent;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
}

.social__link--facebook {
  background-color: #3C5B9B;
  &:hover, &:focus {
    background-color: darken(#3C5B9B, 8%);
  }
}

.social__link--twitter {
  background-color: #2DAAE1;
  &:hover, &:focus {
    background-color: darken(#2DAAE1, 8%);
  }
}

.social__link--pinterest {
  background-color: #CB2027;
  &:hover, &:focus {
    background-color: darken(#CB2027, 8%);
  }
}

.social__link--linkedin {
  background-color: #0173B2;
  &:hover, &:focus {
    background-color: darken(#0173B2, 8%);
  }
}

.heading-spacing {
  padding-top: 30px;
  padding-bottom: 30px;

  @media #{$medium} {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  @media #{$larger} {
    padding-top: 110px;
    padding-bottom: 110px;
  }

  .heading-spacing__h {
    margin-bottom: 0;
  }
}

.padding-block {

  padding: 20px 0;
  @media #{$medium} {
    padding: 60px 0 60px 0;
  }
  @media #{$larger} {
    padding: 110px 0 80px 0;
  }

}

.padding-block--pink {
  background-color: $pink;

  h1 {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

.padding-block--accent {
  background-color: $accent;
}

.padding-block--yellow {
  background-color: $yellow;
}

.textcolor-pink {
  p, span, ul {
    color: $pink;
  }
}

.textcolor-accent {
  @include fs(21);
  p, span, ul {
    color: $accent;
  }
}

.textcolor-white {
  p, span, ul {
    color: $white;
  }

  .button span {
    color: inherit;
  }
}

/* =============================================================================
   PAGES
   ========================================================================== */
// Landing page
.intro {
  margin-bottom: 140px;

  p {
    @include fs(18);
    @include lh(30);
    color: $accent;

    @media #{$medium} {
      @include fs(21);
      @include lh(36);
    }
  }
}

.intro__logo {
  z-index: 1;
  width: 100px;
  display: block;
  position: relative;
  margin: 0 auto;

  @media #{$medium} {
    width: 235px;
    position: absolute;
    top: -100px;
    left: -30px;
  }
}

.intro__container {
  background-color: $yellow;
  margin-left: 10.60%;
  position: relative;
  padding-top: 20px;

  @media #{$medium} {
    padding-top: 160px;
  }
}

.intro__text {
  max-width: 550px;
  width: 75%;
  position: relative;
  z-index: 1;
  padding-top: 30px;

  p {
    margin-bottom: 0;
  }

  @media #{$medium} {
    padding-top: 0;
    width: 50%;
  }
}

.intro__image {
  position: absolute;

  top: 80px;
  right: 0;
  top: auto;
  width: 25%;
  display: none;

  @media #{$medium} {
    display: block;
    //right: -5px;
    //top: 45px;
    //max-width: 755px;
    //width: 50%;

    right: -130px;
    bottom: 0;
    max-width: 550px;
    width: 50%;
  }

  @media #{$larger} {
    right: -300px;
    bottom: -250px;
  }

  @media #{$huge} {
    right: -400px;
  }
  @media #{$massive} {
    right: -500px;
  }
}

.intro__shape {
  display: block;
  position: absolute;
  background-repeat: no-repeat;
}

.intro__shape--1 {
  max-width: 110%;
  width: 150%;
  height: 200%;
  top: -40%;
  left: -20%;
  background-size: cover;
  background-position: center right;
  z-index: 0;

  @media #{$medium} {
    max-width: 1370px;
    height: 1240px;
    top: -370px;
    left: -680px;
  }
}

.intro__shape--2 {
  max-width: 870px;
  width: 220px;
  height: 175px;
  background-size: contain;
  background-position: center center;
  right: -35px;
  bottom: -110px;
}

.intro__drawing {
  position: absolute;
  margin-left: 20px;
  z-index: 1;
  margin-top: -40px;
  width: 85px;

  @media #{$medium} {
    width: 195px;
  }

  @media #{$larger} {
    margin-top: -75px;
    margin-left: 60px;
    width: 345px;
  }
}

.medium--hide {
  display: none;
  @media #{$medium} {
    display: block;
  }
}

.medium--show {

  @media #{$maxMedium} {
    display: none;
  }
}

.larger--hide {
  // display: none;
  @media #{$larger} {
    // display: block;
    display: none;
  }
}

.larger--show {

  @media #{$maxLarger} {
    display: none;
  }
}

.grid--equal-h {
  @extend .equal-h;
  * {
    flex-wrap: wrap;
    justify-content: center;

  }
}

.flex-switch-order {

}

@media #{$large} {

  .flex-switch-order--negative {
    order: -1;
  }

  .flex-switch-order--positive {
    order: 1;
  }
}

.grid--align-c {
  align-items: center;
}

.blob-fallback-header {
  margin-bottom: $gutter;
  width: 100%;
  @media #{$medium} {
    margin-bottom: $gutter*2;
  }
  img {
    width: 100%;
  }
}

.bottom-shapes {
  position: relative;
  padding-top: 150px;

  // z-index: 99;

  @media #{$larger} {
    padding-top: 100px;
  }
}

.bottom-shapes__shape {
  position: absolute;
  display: block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  z-index: -1;
}

.bottom-shapes__shape--1 {
  top: -465px;
  right: -350px;
  max-width: 925px;
  height: 920px;
  width: 100%;

  top: -165px;
  right: -250px;
  height: 720px;
  width: 500px;

  @media #{$larger} {
    top: -365px;
    right: -250px;
    height: 720px;
    width: 500px;

    top: -260px;
    right: -100px;

  }

  @media #{$massive} {
    /*    top: -465px;
        right: -350px;*/
    max-width: 925px;
    height: 900px;

    width: 100%;

  }
}

.bottom-shapes__shape--2 {
  width: 175px;
  height: 155px;
  right: -60px;
  top: 30px;
  z-index: -2;

  @media #{$medium} {
    //top: -110px;
    top: -105px;
  }
  @media #{$massive} {
    //top: -110px;
    top: -250px;
  }
}

.bottom-shapes__drawing {
  width: 85px;
  width: 150px;
  top: 0;
  position: absolute;
  right: 0;

  @media #{$medium} {
    width: 165px;
    top: -5px;
  }

  @media #{$larger} {
    width: 185px;
    // top: -160px;
    right: 20px;
    top: -80px;
  }

  @media #{$massive} {
    width: 285px;
    /*    top: -260px;
        right: 90px;*/

    top: -160px;
    right: 80px;
  }
}

.bottom-shapes--kinderfeestjes {
  .bottom-shapes__shape--1 {
    @media #{$larger} {
      right: -200px;

    }
    @media #{$massive} {
      right: -500px;
      top: -500px;

    }
  }

  .bottom-shapes__drawing {
    @media #{$larger} {
      top: -100px;

    }
    @media #{$massive} {
      top: -225px;

    }
  }
}

.price-header {
  margin-bottom: $gutter;
}

.price-header__title {
  @extend .h2;
  color: $white;
  display: block;
  margin-bottom: 10px;

}

.price-header__subtitle {
  @extend .h3;
  color: $white;
  display: block;

}

.dib.dib {
  display: inline-block;
}

/* =================================================
   blobs
   ================================================= */

.block-with-goat-blob {
  position: relative;

  &:after {
    @media #{$larger} {
      content: '';
      background-size: contain;
      background-image: url("../images/goat2.png");
      width: 1096px;
      width: 548px;
      width: 274px;
      height: 1439px;
      height: 719.5px;
      height: 359px;
      //transform: rotate(-25deg);

      position: absolute;
      right: 0;
      bottom: 0;
      margin-right: -60px;
      margin-bottom: -200px;
    }

    @media #{$huge} {
      margin-right: 0;
      width: 365.333333333px;
      height: 479.33335px;
    }

  }
}

.block-with-landscape-blob {
  //home-landscape-blob
  position: relative;
  &:after {
    position: absolute;
    left: 0;
    bottom: 0;

    background-image: url("../images/home-landscape-blob.png");
    /*    width: 875px;
        height: 830px;*/
    background-size: contain;
    background-repeat: no-repeat;
    transition: $transitionDefault;

    transform: translateX(-100%);

    @media #{$large} {
      content: '';
      margin-left: 100px;
      margin-bottom: -300px;
      width: calc(875px / 1.5);
      height: calc(830px / 1.5);
    }

    @media #{$larger} {
      margin-left: 100px;
      margin-bottom: -250px;
      width: calc(875px / 1.25);
      height: calc(830px / 1.25);
    }

    @media #{$massive} {
      width: 875px;
      height: 830px;
      margin-left: 150px;
      margin-bottom: -400px;
    }

  }

}

.block-with-contact-blob {
  position: relative;
  &:after {
    @media #{$large} {
      content: '';
      background-size: contain;
      background-image: url("../images/contact-yellow-blob.png");
      background-repeat: no-repeat;
      background-position-x: right;
      pointer-events: none;
      width: 714px;
      height: 791px;
      //transform: rotate(-25deg);
      position: absolute;
      right: 0;
      bottom: 0;
      margin-bottom: -400px;
      margin-right: -500px;

      /*      margin-right: -260px;
            margin-bottom: -200px;*/
    }

    @media #{$larger} {
      margin-right: -400px;
    }
    @media #{$massive} {
      margin-right: -300px;

    }
  }
}

.block-with-contact-blob--high {

  &:after {
    @media #{$large} {
      margin-bottom: -200px;
    }
  }
}

.about-animal-blob {
  display: none;

  @media #{$larger} {
    display: block;
  }

  .about_rabbit_drawing {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 337px;
    height: 253px;
    z-index: 12;

    @media #{$larger} {
      margin-bottom: 350px;
    }
    @media #{$massive} {
      margin-bottom: 300px;
    }
  }
  .piglet-blob {
    position: absolute;
    left: 0;
    bottom: 0;
    //margin-bottom: -300px;
    z-index: 11;

    @media #{$larger} {
      width: calc(663px / 1.5);
      height: calc(630px / 1.5);
      margin-bottom: -250px;
      margin-left: -150px;
    }
    @media #{$massive} {
      width: 663px;
      height: 630px;
      margin-bottom: -300px;
      margin-left: -125px;
    }
  }

  .yellow-blob {
    left: 0;
    bottom: 0;
    position: absolute;
    height: 372px;
    width: 256px;

    //transform: rotate(-77deg);

    @media #{$larger} {
      margin-bottom: 200px;
      margin-left: -120px;
    }

    @media #{$massive} {
      margin-bottom: 200px;
      margin-left: -120px;

    }
  }

}

.block-with-rooster-blob {
  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    background-size: contain;
    background-image: url("../images/blob-rooster@2x.png");
    background-repeat: no-repeat;

    width: 552px;
    height: 804px;
    display: none;

    @media #{$larger} {
      display: block;
      width: calc(552px / 1.5);
      height: calc(804px / 1.5);
      margin-top: -100px;
      margin-right: -100px;

    }

    @media #{$massive} {

      width: 552px;
      height: 804px;
      margin-right: -100px;
      margin-top: -300px;
    }

  }
}

.block-with-about-blob {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    background-size: contain;
    background-image: url("../images/about-blob@2x.png");
    background-repeat: no-repeat;

    display: none;

    @media #{$larger} {
      display: block;
      width: calc(735px / 1.5);
      height: calc(558px / 1.5);
      margin-bottom: -150px;
      margin-right: -100px;

    }

    @media #{$massive} {

      width: 735px;
      height: 558px;
      margin-right: -200px;
      margin-bottom: -300px;
    }

  }
}

.block-with-goat-bean-blob {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    background-size: contain;
    background-image: url("../images/goat-2-blob@2x.png");
    background-repeat: no-repeat;

    display: none;

    @media #{$larger} {
      display: block;
      width: calc(437px / 1.5);
      height: calc(629px / 1.5);
      //margin-bottom: -100px;
      margin-bottom: 100px;
      //margin-left: -275px;
      margin-left: -100px;

    }

    @media #{$massive} {

      width: 437px;
      height: 629px;
      margin-left: -100px;
      margin-bottom: 50px;
    }

  }
}

.block-with-6j-blob {
  position: relative;

  &:after {
    @media #{$larger} {
      content: '';
      background-size: contain;
      background-image: url("../images/big-6j-blob.png");

      width: calc(822px / 1.5);
      height: calc(640px / 1.5);
      //transform: rotate(-25deg);
      position: absolute;
      right: 0;
      bottom: 0;
      margin-right: -160px;
      margin-bottom: -300px;
    }

    @media #{$massive} {
      width: 822px;
      height: 640px;
      margin-right: -450px;
      margin-bottom: -500px;
    }

  }
}

.sideblobs {
  //position: absolute;
  //right: 0;
}

.sideblobs--6j {
  display: none;
  @media #{$larger} {
    display: block;
  }
  .sideblobs__img {

    position: absolute;
    right: 0;
    top: 0;

    z-index: 11;
    width: 250px;
    margin-right: 0;
    margin-top: 600px;

    @media #{$massive} {
      width: 342px;
      margin-top: 600px;
      margin-right: -50px;
    }
  }

  .sideblobs__yellow {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    width: 500px;
    margin-right: -200px;
    margin-top: 600px;

    @media #{$massive} {

      margin-right: -500px;
      margin-top: 200px;
      width: 733px;
    }
  }

}

.atelier-visuals {
  display: none;
  @media #{$larger} {
    display: block;
  }

  .atelier-shapes__visual {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 11;
    margin-right: -175px;
    margin-top: 500px;
    width: 450px;

    @media #{$massive} {
      margin-right: -100px;
      width: 550px;
      // width: 450px;
      // margin-right: -450px;
    }
  }
}

.pink-blob {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin-left: -350px;
  margin-bottom: 250px;
  width: 600px;
  display: none;
  @media #{$larger} {
    display: block;
  }

  @media #{$massive} {
    margin-left: -350px;
    margin-bottom: 100px;
    width: 800px;
  }
}

.atelier-drawing {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: -200px;
  width: 250px;
  margin-left: -225px;
  display: none;
  @media #{$larger} {
    display: block;
  }

  @media #{$massive} {
    width: 380px;
    margin-left: -300px;
  }
}

/* =================================================
   About
   ================================================= */
.pull-top {
  margin-top: -50px;

  @media #{$medium} {
    margin-top: -200px;
  }
}

.team-member {

}

.team-member__name {
  @extend .h2;
  display: block;
  margin-bottom: $gutter*2;
}

.team-member__visual {
  background-position: center;
  overflow: hidden;
  width: 100%;
  display: inline-block;
  border-radius: 50%;
  margin-bottom: $gutter*2;

  @media #{$medium} {
    width: 300px;
    height: 300px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

/* =================================================
   Workshops
   ================================================= */

.workshop {
  margin-bottom: $gutter*2;
  .button.button--small {
    float: none;
    display: inline-block;
  }
}

.workshop__date {
  @include fs(18);
  font-weight: 600;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: $accent;
}


.workshop__header {
  display: block;
  width: 100%;
  @include clearfix;
  position: relative;
  //margin-bottom: -10px;

  > .grid {
    margin-bottom: 0px;
  }
}

.workshop__content {
  background-color: $pink;
  padding: 20px;
  color: $accent;
}

.workshop__title {
  @include fs(25);
  font-weight: 600;
  display: block;
  max-width: calc(100% - 40px);
  margin-bottom: 10px;

}

.workshop__subtitle {
  @include fs(20);
  font-weight: 100;
  display: block;
  font-style: italic;

}

.workshop__status {
  @include fs(16);
  background-color: $accent;
  color: $white;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: 600;
  height: 30px;
  @include display-font;
  line-height: normal;
  @include lh(18);
  letter-spacing: 0.1em;
  margin-right: 5px;
}

.status-and-toggle {
  position: absolute;
  right: 0;
  top: 0;
}
.workshop__toggle {
  float: right;
  width: 30px;
  height: 30px;
  background-color: $accent;
  color: $white;
  text-align: center;
  border-radius: 50%;
  @include lh(30);

  &.active {
    i {
      transform: rotate(180deg);
      top: -2px;
    }
  }

  i {
    display: inline-block;
    position: relative;
    top: 0px;
    transition: $transitionDefault;
    @include fs(12);

  }

}
.js {
  .workshop__info {
    overflow: hidden;
    transition: $transitionDefault;
    //max-height: 0;
    display: none;
    //box-sizing: border-box;
    //
  }
}
.workshop__info {
  overflow: hidden;
  transition: $transitionDefault;
  //max-height: 0;
  display: none;
  //box-sizing: border-box;
  //

  &.active {
    margin-top: 10px;
    max-height: 800px;
    @media #{$medium} {
      max-height: 400px;
    }
  }
}

.workshop-info-item {
  //margin-top: 5px;
  .grid {
    margin-bottom: 0;
  }

}

.workshop__info__value {
  color: $black;
  font-style: italic;
  font-weight: 300;
}

/* =================================================
   Gallery
   ================================================= */

.gallery-item {
  margin-bottom: $gutter;
}
.gallery-item__visual {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-size: cover;
  transition: $transitionDefault;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: $transitionDefault;
    background-color: $white;
  }


}
.gallery-item__link {

    display: block;
    &:hover, &:focus, &:active {
      .gallery-item__visual {
        &:after {
          opacity: 0.4;
        }

      }
    }


}

/* =============================================================================
   PRINT
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
  @page {
    margin: 2cm 0.5cm 1cm;
  }

  html {
    font-size: 10px;
  }

  * {
    background-color: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  .ir {
    text-indent: 0;
    overflow: visible;
    direction: inherit;
  }

  a, a:visited {
    text-decoration: underline;
  }

  tr, img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }

  .page-footer, .main-nav {
    display: none;
  }

  .medium--one-half {
    width: 50%;
  }
  .medium--two-thirds {
    width: 66.66%;
  }
  .medium--one-third {
    width: 33.33%;
  }
  .medium--three-quarters {
    width: 75%;
  }
  .medium--one-quarter {
    width: 25%;
  }
}
